<template>
  <div  class="mainwrap adedit" v-loading="loading">
      <div class="title">蓋板廣告-新增/編輯</div>

      <div class="formwrap">
        <el-form
            :model="adForm"
            :rules="rules"
            ref="adForm"
            label-width="150px"
            label-position="left"
        >
            <el-form-item label="標題" prop="name">
                <el-input
                    v-model="adForm.name"
                    placeholder="請輸入標題"
                ></el-input>
            </el-form-item>

            <!-- type="date"/type="datetime" & value-format="yyyy-MM-dd"/value-format="yyyy-MM-dd HH:mm:ss" -->
            <el-form-item label="開始時間" required class="inps">
                <!-- <el-date-picker
                    v-model="adForm.startTime"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="選擇推送日期"
                    :picker-options="pickerOptions"
                >
                </el-date-picker> -->
                <el-form-item prop="startTime" style="margin:0 10px 0 0;">
                  <el-date-picker
                      v-model="adForm.startTime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :default-value="defaultDateValue1"
                      :picker-options="pickerOptions1"
                      placeholder="選擇推送日期">
                  </el-date-picker>
                </el-form-item>
                -
                <el-form-item prop="endTime" style="margin:0 10px;">
                  <el-date-picker
                      v-model="adForm.endTime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :default-value="defaultDateValue2"
                      :picker-options="pickerOptions2"
                      placeholder="選擇结束日期">
                  </el-date-picker>
                </el-form-item>
            </el-form-item>

            <el-form-item label="展示次數" required="" class="inp">
              <div class="ips">
                每
                <el-form-item  prop="day">
                  <el-input v-model="adForm.day"></el-input>
                </el-form-item>
                天顯示
                <el-form-item  prop="showNum">
                  <el-input v-model="adForm.showNum"></el-input>
                </el-form-item>
                次功能
              </div>
            <div class="tis">
              <p>時間區間為2023/1/1-2023/1/8 共8天, 展示的天數只能是 1，2，4，8</p> 
            </div>
            </el-form-item>
            <el-form-item label="鏈接" prop="linkType">
                <el-radio-group v-model="adForm.linkType" style="width: 650px" @change="changeLinkType">
                    <el-radio :label="1">餐廳資訊</el-radio>
                    <el-radio :label="2">最新消息</el-radio>
                    <el-radio :label="3">優惠券</el-radio>
                    <!-- <el-radio :label="4">通知信息</el-radio> -->
                    <el-radio :label="5">外鏈</el-radio>
                </el-radio-group>

                <div v-if="adForm.linkType !== '' && adForm.linkType !== 5" class="inlineblock">
                    <el-input disabled v-model="adForm.linkName"></el-input>
                    <el-button type="primary" @click="openLinkVisible">選擇</el-button>
                </div>
                <div v-if="adForm.linkType === 5">
                    <el-input
                        v-model="adForm.url"
                        placeholder="請輸入跳轉鏈接，https://或http://開頭"
                    ></el-input>
                </div>
            </el-form-item>

            <el-form-item label="圖片" prop="imageUrl">
                <el-upload
                    class="img-uploader"
                    action=""
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleChange"
                >
                    <img v-if="adForm.imageUrl" :src="adForm.imageUrl" class="imgbox"/>
                    <i v-else class="el-icon-plus"></i>
                    <div @click.stop.prevent v-if="adForm.imageUrl" class="deletewrap">
                        <i class="el-icon-delete" @click="handleRemove"></i>
                    </div>
                    <div class="el-upload__tip" slot="tip">
                        只能上傳jpg/png文件，且不超過2MB，圖片比例 1020px：1578px
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="是否發佈" prop="isActive">
                <el-select v-model="adForm.isActive" placeholder="请选择是否發佈">
                    <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        </div>

        <div class="btnwrap">
            <div class="btnlist">
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <el-button @click="cancel">取消</el-button>
            </div>
        </div>

        <el-dialog title="選擇鏈接" :visible.sync="linkVisible" width="40%">
            <div class="linkSearchBox">
                <el-input v-model="linkSearch"></el-input>
                <el-button type="primary" @click="onSearch">搜索</el-button>
            </div>
            <el-table :data="linkVisibleData">
                <el-table-column property="value" label="名稱"></el-table-column>
                <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                    <el-button @click="selectLink(scope.row)" type="primary" plain>
                    選擇
                    </el-button>
                </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                background
                :hide-on-single-page="isSinglePage"
                layout="prev, pager, next,jumper"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="currentPage"
                :total="tableDataTotal"
                >
                </el-pagination>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import * as api from "./api"
import {tableDataPagination,checkURL} from "@/utils/common.js"
export default {
    data(){
        var validateLink = (rule, value, callback) => {
            if (
                this.adForm.linkType !== "" &&
                this.adForm.linkType !== 5 &&
                this.adForm.linkName === ""
            ) {
                callback(new Error("請選擇跳轉鏈接"));
            } else if (
                this.adForm.linkType !== "" &&
                this.adForm.linkType === 5 &&
                this.adForm.url === ""
            ) {
                callback(new Error("請輸入跳轉鏈接"));
            }else if (
                this.adForm.linkType !== "" &&
                this.adForm.linkType === 5 &&
                this.adForm.url !== "" && !checkURL(this.adForm.url)
            ) {
                callback(new Error("請輸入正確的鏈接"));
            }
            callback();
        }
        var validateImageUrl= (rule, value, callback) => { 
            if (this.adForm.imageUrl === "") {
                callback(new Error("請上傳圖片"));
            }
            callback();
        }
        return{
            loading:false,
            adForm:{
                id:"",
                name:"",
                startTime:"",
                endTime:"",
                linkType:"",
                linkId:"",
                linkName:"",
                url:"",
                imageUrl:"",
                isActive:"",
                day: null,
                showNum: null,
            },
            statusOptions: [
                { value: true, label: "是" },
                { value: false, label: "否" },
            ],
            rules:{
                name: [{ required: true, message: "請輸入標題", trigger: "blur" }],
                startTime: [
                    { required: true, message: "請選擇日期", trigger: "blur" }
                ],
                endTime: [
                    { required: true, message: "請選擇日期", trigger: "blur" }
                ],
                linkType:[{ required: true, message: "請選擇跳轉鏈接", trigger: "blur" },
                    { validator: validateLink, trigger: ["change", "blur"] }
                ],
                imageUrl: [
                    {required: true, message: "請上傳圖片", trigger: ["change", "blur"]},
                    {validator:validateImageUrl,trigger: "change"}
                ],
                isActive: [
                    { required: true, message: "請選擇是否發佈", trigger: "change" },
                ],
                day: [
                    { required: true,  message: "請填寫展示間隔", trigger: "blur" },
                ],
                showNum: [
                    { required: true,  message: "請填寫展示次數", trigger: "blur" },
                ],
            },
            // pickerOptions: {
            //     disabledDate(time) {
            //         //此条为设置禁止用户选择今天之前的日期，包含今天。
            //         // return time.getTime() <= (Date.now());
            //         //此条为设置禁止用户选择今天之前的日期，不包含今天。
            //         return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
            //     },
            // },
            pickerOptions1:{
                disabledDate:this.dealDisabledDate1,
            },
            pickerOptions2:{
                disabledDate:this.dealDisabledDate2,
            },
            allRestauranList:[],    //餐廳
            allNewsList:[],     //最新消息
            allCouponList:[],    //優惠券
            allPushList:[],     //通知訊息
            linkVisible: false,
            linkVisibleData: [],
            isSinglePage: false,
            currentPage: 1,
            pageSize:10,
            linkSearch:"",
            tableDataTotal:0,
            defaultDateValue1:"",
            defaultDateValue2:"",
        }
    },
    async created(){
        await this.getSelectList(1);
        await this.getSelectList(2);
        await this.getSelectList(3);
        await this.getSelectList(4);
        let queryObj = this.$route.query || {};
        if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
            this.getDetails(queryObj.id);
        } else {
            this.loading = false;
        }
    },
    methods:{
        async getSelectList(type){
            this.loading = true;
            await api.getSelectData({ type }).then(response => {
                if (type === 1) {
                    this.allRestauranList = response.data;
                } else if (type === 2) {
                    this.allNewsList = response.data;
                }else if (type === 3) {
                    this.allCouponList = response.data;
                }else if (type === 4) {
                    this.allPushList = response.data;
                }
            });
        },
        // 計算禁用日期
        dealDisabledDate1(time){
            if(this.adForm.endTime){
                this.defaultDateValue1=this.adForm.endTime;
                return time.getTime() > new Date(this.adForm.endTime);
            }else{
                this.defaultDateValue1="";
                return false
            }
        },
         // 計算禁用日期
        dealDisabledDate2(time){
            if(this.adForm.startTime){
                this.defaultDateValue2=this.adForm.startTime;
                return time.getTime() < new Date(this.adForm.startTime);
            }else{
                this.defaultDateValue2="";
                return false
            }
        },
        getDetails(id){
            api.getCoverDetails(id).then(res=>{
                this.adForm = Object.assign({}, this.adForm, res.data);
                if(this.adForm.linkType!==5){
                    this.adForm.linkName = this.filterLinkName(Number(this.adForm.linkId));
                }
                this.loading = false;
            })
        },
        filterLinkName(linkId){
            let nameItem=[];
            if(this.adForm.linkType===1){
                nameItem=this.allRestauranList.filter(item=>linkId===item.key);
            }else if(this.adForm.linkType===2){
                nameItem=this.allNewsList.filter(item=>linkId===item.key);
            }else if(this.adForm.linkType===3){
                nameItem=this.allCouponList.filter(item=>linkId===item.key);
            }else if(this.adForm.linkType===4){
                nameItem=this.allPushList.filter(item=>linkId===item.key);
            }
            return nameItem.length>=1? nameItem[0].value:"";
        },
        // 上傳圖片
        async handleChange(file, fileList) {
            if (!this.beforeUpload(file.raw)) return;
            let params = new FormData();
            params.append("file", file.raw);
            await api.uploadImage(params).then(res=>{
                if(res.systemCode===200){
                    this.adForm.imageUrl = res.data;
                    this.$message.success('圖片上傳成功');
                }
            })
            this.$refs.adForm.validateField("imageUrl");
        },
        // 限制格式和大小
        beforeUpload(file) {
            const acceptArr = ["image/jpeg", "image/png"];
            const isJPG = acceptArr.indexOf(file.type) !== -1;
            const isLt2M = file.size / 1024 / 1024 < 2;
            // }
            if (!isJPG) {
                this.$message.error("上傳圖片只能是 JPG/PNG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上傳圖片大小不能超過 2MB!");
            }
            return isJPG && isLt2M;
        },
        handleSuccess(res, file) {
            console.log(file);
        },
        handleRemove() {
            this.adForm.imageUrl = "";
            this.$refs.adForm.validateField("imageUrl");
        },
        onSubmit(){
            this.$refs["adForm"].validate(valid => {
                if (valid){
                    this.loading=true;
                    let params = {
                        name:this.adForm.name,
                        startTime:this.adForm.startTime.length<=10? this.adForm.startTime +" "+"00:00:00" : this.adForm.startTime,
                        endTime:this.adForm.endTime.length<=10? this.adForm.endTime +" "+"00:00:00" : this.adForm.endTime,
                        linkType:this.adForm.linkType,
                        linkId:this.adForm.linkId,
                        url:this.adForm.url,
                        imageUrl:this.adForm.imageUrl,
                        day:Number(this.adForm.day),
                        showNum:Number(this.adForm.showNum),
                        isActive:this.adForm.isActive
                    };
                    if(this.adForm.id){
                        params.id=this.adForm.id;
                    }
                    for (let key in params){
                        if(params[key]===""){
                            delete params[key];
                        }
                    }
                    api.coverEdit(params).then(res=>{
                        if(res.systemCode===200){
                            this.$message.success('提交成功');
                            this.$router.push({ path: "/cms/coverad" });
                        }
                        this.loading=false;
                    })
                }else {
                    console.log("error submit!!");
                    return false;
                }
            })
        },
        cancel(){
            this.$router.push({ path: "/cms/coverad" });
        },
        changeLinkType(){
            this.adForm.linkId = "";
            this.adForm.linkName = "";
            this.adForm.url="";
        },
        // 查詢
        onSearch() {
            this.currentPage=1;
            if (this.adForm.linkType === 1) {
                this.getPageRes(this.allRestauranList);
            } else if (this.adForm.linkType === 2) {
                this.getPageRes(this.allNewsList);
            } else if (this.adForm.linkType === 3) {
                this.getPageRes(this.allCouponList);
            } else if (this.adForm.linkType === 4) {
                this.getPageRes(this.allPushList);
            }
        },
        // selectLink
        selectLink(item) {
            this.adForm.linkId=item.key;
            this.adForm.linkName=item.value;
            this.linkVisible=false;
        },
        openLinkVisible() {
            this.currentPage=1;
            this.linkSearch="";
            if (this.adForm.linkType === 1) {
                this.getPageRes(this.allRestauranList);
            } else if (this.adForm.linkType === 2) {
                this.getPageRes(this.allNewsList);
            } else if (this.adForm.linkType === 3) {
                this.getPageRes(this.allCouponList);
            } else if (this.adForm.linkType === 4) {
                this.getPageRes(this.allPushList);
            }
            this.linkVisible = true;
        },
        // 處理表格數據
        getPageRes(allData){
            let res=tableDataPagination(allData,this.currentPage,this.pageSize,this.linkSearch);
            this.linkVisibleData=res.dataList;
            this.tableDataTotal=res.dataTotal;
        },
        // 翻頁
        handleCurrentChange(val) {
            this.currentPage=val;
            if (this.adForm.linkType === 1) {
                this.getPageRes(this.allRestauranList);
            } else if (this.adForm.linkType === 2) {
                this.getPageRes(this.allNewsList);
            } else if (this.adForm.linkType === 3) {
                this.getPageRes(this.allCouponList);
            } else if (this.adForm.linkType === 4) {
                this.getPageRes(this.allPushList);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.adedit{
    .formwrap {
        padding-top: 20px;
        padding-left: 20px;
        width: 650px;
        .inlineblock {
            width: 500px;
            .upbtn{
                margin-right: 10px;
            }
            .el-input {
                width: 50%;
                margin-right: 10px;
            }
            .upload-demo {
                display: inline-block;
            }
        }
    }
    /deep/.el-upload__tip{
        width: 600px;
    }
    .linkSearchBox{
        width: 500px;
        margin-bottom: 10px;
        .el-input {
            width: 250px;
            margin-right: 10px;
        }
  }
  .inp {
    width: 100%;
    /deep/.el-form-item__content {
      display: flex;
      flex-wrap: wrap;
    }
    /deep/.el-input{
        width: 100px;
        margin: 0 10px;
    }
  }
  .inps {
    width: 100%;
    /deep/.el-form-item__content {
      display: flex;
    }
  }
  .ips {
    display: flex;
    width: 100%;
    height: 40px;
  }
  .tis {
    width: 100%;
    font-size: 12px;
    p {
      padding: 0;
      margin: 0 0 0 0;
      color: #606266;
    }
  }
}
</style>