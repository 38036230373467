import { getRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 獲取列表
export const getCoverBanner=(params)=>{
    return getRequest(API.coverBanner,params);
}
// 詳情
export const getCoverDetails=(params)=>{
    return getRequest(API.coverBanner+'/'+params);
}
// 刪除
export const deleteCover=(params)=>{
    return deleteRequest(API.coverBanner+'/'+params);
}
// 獲取選擇列表
export const getSelectData=(params)=>{
    return getRequest(API.bannerSelectData,params);
}
// 編輯
export const coverEdit=(params)=>{
    return postRequest(API.coverBannerEdit,params);
}
// 上傳圖片
export const uploadImage=(params)=>{
    return postFormReq(API.uploadImage,params);
}